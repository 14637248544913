import { RouteRecordRaw } from "vue-router";
import CalculatorGuest from "@/pages_guest/CalculatorGuest.vue";
import PopularSearchGuest from "@/pages_guest/PopularSearchGuest.vue";
import InsulinCalculatorGuest from "@/pages_guest/InsulinCalculatorGuest.vue";
import ContactUsGuest from "@/pages_guest/ContactUsGuest.vue";
import PrivacyPolicyGuest from "@/pages_guest/PrivacyPolicyGuest.vue";
import TermsGuest from "@/pages_guest/TermsGuest.vue";
import GenerateMealsGuest from "@/pages_guest/GenerateMealsGuest.vue";

// Guest User Pages
const GuestLayout = () => import("@/layout_guest/GuestLayout.vue");

const LandingGuest = () => import("@/pages_guest/LandingPageGuest.vue");
const ProductDetailGuest = () => import("@/pages_guest/ProductDetailGuest.vue");

// Router
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "GuestLayout",
    meta: {
      guestOnly: true,
    },
    component: GuestLayout,
    children: [
      {
        path: "/",
        name: "LandingGuest",
        component: LandingGuest,
      },
      {
        path: "/product-detail",
        name: "ProductDetailGuest",
        component: ProductDetailGuest,
      },
      {
        path: "/calculator",
        name: "CalculatorGuest",
        component: CalculatorGuest,
      },
      {
        path: "/popular-search",
        name: "PopularSearchGuest",
        component: PopularSearchGuest,
      },
      {
        path: "/insulin-calculator",
        name: "InsulinCalculatorGuest",
        component: InsulinCalculatorGuest,
      },
      {
        path: "/generate-meals",
        name: "GenerateMealsGuest",
        component: GenerateMealsGuest,
      },
      {
        path: "/contact-us",
        name: "ContactUsGuest",
        component: ContactUsGuest,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicyGuest",
        component: PrivacyPolicyGuest,
      },
      {
        path: "/terms",
        name: "TermsGuest",
        component: TermsGuest,
      },
    ],
  },
];

export default routes;
