<template>
  <div class="terms">
    <h1 class="text-2xl">Terms and Conditions</h1>
    <div class="terms--container">
      <div class="terms--text-block">
        <h2 class="text-lg-semibold">AGREEMENT TO TERMS</h2>
        <div class="terms--text-container">
          <p class="text-md-regular">
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”)
            and CryptHub ("Company ," “we," “us," or “our”), concerning your
            access to and use of thehttps://crypthub.iowebsite as well as any
            other media form, media channel, mobile website or mobile
            application related, linked, or otherwise connected thereto
            (collectively, the “Site”). You agree that by accessing the Site,
            you have read, understood, and agreed to be bound by all of these
            Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
            THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss" src="@/pages_guest/scss/terms.scss" />
