export function getGlycemicCarbsStatus(value: number) {
  if (value < 10) return "low";

  if (value >= 10 && value <= 20) return "medium";

  return "high";
}

export function getGlycemicIndexStatus(value: number) {
  if (value <= 55) return "low";

  if (value > 55 && value <= 69) return "medium";

  return "high";
}

export function getGlycemicLoadStatus(value: number) {
  if (value <= 10) return "low";

  if (value > 10 && value < 20) return "medium";

  return "high";
}
