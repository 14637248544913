import { GuestCalculatorProduct } from "@/services/api/pages/product-detail/entities/guest-product";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useCalculatorStore = defineStore(
  "calculator-store",
  () => {
    const products = reactive({
      value: [] as GuestCalculatorProduct[],
    });

    const measurment = reactive({
      value: "Grams",
    });

    return {
      products,
      measurment,
    };
  },
  { persist: true }
);
