<template>
  <div class="calculator">
    <div class="calculator--banner">
      <div class="calculator--banner-column">
        <div class="calculator--banner-details">
          <h2 class="display-xl">
            {{ $t(`productDetails.title`) }}
          </h2>
          <p class="text-md-regular">
            <i class="icon icon-info"></i>
            {{ $t(`productDetails.details`) }}
          </p>
        </div>
      </div>
      <div class="calculator--table-content">
        <div class="calculator--search-results">
          <CalculatorMobileRow
            v-for="product in calculatorStore.products.value"
            :key="product.image_uuid"
            :product="product"
          />
          <div class="calculator--table-actions">
            <button
              class="btn btn-default btn-icon btn-medium"
              @click="goToSearch"
            >
              <i class="icon icon-plus"></i>
              {{ $t(`productDetails.btn.addPosition`) }}
            </button>
            <router-link
              style="text-decoration: none"
              :to="{
                name: 'GenerateMealsGuest',
              }"
            >
              <button class="btn btn-default btn-icon btn-medium">
                <i class="icon icon-repeat"></i>
                {{ $t(`productDetails.btn.generateMeals`) }}
              </button>
            </router-link>
          </div>
        </div>
        <CalculatorMobileSum />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from "@/router";
import CalculatorMobileSum from "@/pages_guest/glycemic_calculator/CalculatorMobileSum.vue";
import CalculatorMobileRow from "@/pages_guest/glycemic_calculator/CalculatorMobileRow.vue";

import { useCalculatorStore } from "@/store/calculatorStore";

const calculatorStore = useCalculatorStore();

function goToSearch() {
  router.push({
    name: "LandingGuest",
  });
}

function removeProduct(product_index: number) {
  calculatorStore.products.value.splice(product_index, 1);
}
</script>

<style
  scoped
  lang="scss"
  src="@/pages_guest/glycemic_calculator/scss/glycemic-calculator-mobile.scss"
/>
