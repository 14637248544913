<template>
  <div class="popular-search">
    <div class="popular-search--banner">
      <div class="popular-search--banner-column">
        <img
          class="popular-search__tomato"
          src="@/assets/image/tomato.svg"
          alt="Tomato logo"
        />
        <h2 class="display-xl">
          {{ $t(`popularSearch.title`) }}
        </h2>
      </div>
    </div>
    <div
      v-if="search_results.value.length"
      class="popular-search--search-results"
    >
      <div
        class="popular-search--search-item"
        v-for="result in search_results.value"
        :key="result.photo_uuid"
      >
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'ProductDetailGuest',
            query: {
              query: result.title,
            },
          }"
        >
          <div class="popular-search--search-block">
            <img
              class="popular-search__search-img"
              :src="`${imageRoot}product-detail/product-photo/${result.photo_uuid}/`"
              alt="image"
            />
            <div class="popular-search--search-details">
              <p class="text-lg-semibold">{{ result.title }}</p>
              <div class="popular-search--result-body">
                <div class="popular-search--result-body-item">
                  <p class="text-md-regular">
                    {{ $t(`productDetails.carbsPerGram`) }}:
                  </p>
                  <div class="popular-search--result-body-row">
                    <p class="text-md-semibold">
                      {{
                        averageCarbs(
                          result.carbs_per_100g_min,
                          result.carbs_per_100g_max
                        )
                      }}
                      <span
                        :class="
                          getGlycemicCarbsStatus(
                            averageCarbs(
                              result.carbs_per_100g_min,
                              result.carbs_per_100g_max
                            )
                          )
                        "
                        >({{
                          $t(
                            `status.${getGlycemicCarbsStatus(
                              averageCarbs(
                                result.carbs_per_100g_min,
                                result.carbs_per_100g_max
                              )
                            )}`
                          )
                        }})</span
                      >
                    </p>
                    <CustomTooltip :i18Key="'carb'" />
                  </div>
                </div>
                <div class="popular-search--result-body-item">
                  <p class="text-md-regular">
                    {{ $t(`productDetails.glycemicIndex`) }}:
                  </p>
                  <div class="popular-search--result-body-row">
                    <p class="text-md-semibold">
                      {{
                        `${result.glycemic_index_min}-${result.glycemic_index_max}`
                      }}
                      <span
                        :class="
                          getGlycemicIndexStatus(result.glycemic_index_max)
                        "
                        >({{
                          $t(
                            `status.${getGlycemicIndexStatus(
                              result.glycemic_index_max
                            )}`
                          )
                        }})</span
                      >
                    </p>
                    <CustomTooltip :i18Key="'glIndex'" />
                  </div>
                </div>
                <div class="popular-search--result-body-item">
                  <p class="text-md-regular">
                    {{ $t(`productDetails.glycemicLoad`) }}:
                  </p>
                  <div class="popular-search--result-body-row">
                    <p class="text-md-semibold">
                      {{
                        `${result.glycemic_load_min}-${result.glycemic_load_max}`
                      }}
                      <span
                        :class="getGlycemicLoadStatus(result.glycemic_load_max)"
                        >({{
                          $t(
                            `status.${getGlycemicLoadStatus(
                              result.glycemic_load_max
                            )}`
                          )
                        }})</span
                      >
                    </p>
                    <CustomTooltip :i18Key="'glLoad'" />
                  </div>
                </div>
              </div>
            </div>
            <i class="icon icon-arrow-right"></i>
          </div>
        </router-link>
        <div class="popular-search__hr"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive } from "vue";
import {
  getGlycemicCarbsStatus,
  getGlycemicIndexStatus,
  getGlycemicLoadStatus,
} from "@/features/useGlycemicParameters";
import CustomTooltip from "@/components/CustomTooltip.vue";

const search_results = reactive({
  value: [
    {
      title: "apple",
      photo_uuid: "c69a09a2-190c-420c-9d4a-27e6bfc887a1",
      carbs_per_100g_min: 10,
      carbs_per_100g_max: 15,
      glycemic_index_min: 36,
      glycemic_index_max: 52,
      glycemic_load_min: 3,
      glycemic_load_max: 7,
    },
    {
      title: "Watermelon",
      photo_uuid: "4a72cb8c-dfea-47dc-8fd8-993413415441",
      carbs_per_100g_min: 6,
      carbs_per_100g_max: 8,
      glycemic_index_min: 72,
      glycemic_index_max: 76,
      glycemic_load_min: 4,
      glycemic_load_max: 6,
    },
    {
      title: "lemon",
      photo_uuid: "6c391b14-bfcf-42cd-adb9-9cfd187cf6db",
      carbs_per_100g_min: 9,
      carbs_per_100g_max: 17,
      glycemic_index_min: 20,
      glycemic_index_max: 30,
      glycemic_load_min: 1,
      glycemic_load_max: 5,
    },
  ],
});

const imageRoot = computed(() => {
  return process.env.VUE_APP_API_URL;
});

function averageCarbs(min: number, max: number) {
  return (min + max) / 2;
}
</script>

<style scoped lang="scss" src="@/pages_guest/scss/popular-search.scss" />
