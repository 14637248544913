<template>
  <div class="calculator--table-products-row">
    <p class="col-1 text-md-semibold">{{ product.title }}</p>
    <div class="col-2 calculator--table-products-item">
      <p class="text-md-regular">
        {{ toDecimal(product.cal_carbs_per_100g_min) }} -
        {{ toDecimal(product.cal_carbs_per_100g_max) }}
        <span
          :class="
            getGlycemicCarbsStatus(
              average(
                product.cal_carbs_per_100g_min,
                product.cal_carbs_per_100g_max
              )
            )
          "
          >({{
            $t(
              `status.${getGlycemicCarbsStatus(
                average(
                  product.cal_carbs_per_100g_min,
                  product.carbs_per_100g_max
                )
              )}`
            )
          }})</span
        >
      </p>
    </div>
    <div class="col-3 calculator--table-products-item">
      <p class="text-md-regular">
        {{ `${product.glycemic_index_min}-${product.glycemic_index_max}` }}
        <span :class="getGlycemicIndexStatus(product.glycemic_index_max)"
          >({{
            $t(`status.${getGlycemicIndexStatus(product.glycemic_index_max)}`)
          }})</span
        >
      </p>
    </div>
    <div class="col-4 calculator--table-products-item">
      <p class="text-md-regular">
        {{ toDecimal(product.cal_glycemic_load_min) }} -
        {{ toDecimal(product.cal_glycemic_load_max) }}
        <span
          :class="
            getGlycemicLoadStatus(
              average(
                product.cal_glycemic_load_min,
                product.cal_glycemic_load_max
              )
            )
          "
          >({{
            $t(
              `status.${getGlycemicLoadStatus(
                average(
                  product.cal_glycemic_load_min,
                  product.cal_glycemic_load_max
                )
              )}`
            )
          }})</span
        >
      </p>
    </div>
    <div class="col-5 form-group">
      <input
        type="text"
        placeholder="100"
        :value="product.portion_size"
        @input="updateProductValues(product, $event.target?.value)"
      />
    </div>
    <button class="col-6 btn btn-icon" @click="emit('delete_clicked')">
      <i class="icon icon-trash"></i>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits, watch } from "vue";

import {
  getGlycemicCarbsStatus,
  getGlycemicIndexStatus,
  getGlycemicLoadStatus,
} from "@/features/useGlycemicParameters";
import { useCalculatorStore } from "@/store/calculatorStore";
import { GuestCalculatorProduct } from "@/services/api/pages/product-detail/entities/guest-product";

const calculatorStore = useCalculatorStore();

const emit = defineEmits(["delete_clicked"]);

const props = defineProps({
  product: {
    type: Object as () => GuestCalculatorProduct,
    required: true,
  },
});

function toDecimal(num: number) {
  if (num % 1 === 0) return num.toString();

  const val = num.toFixed(2);

  return val.replace(/0*$/g, ""); //remove trailing zeros
}

function average(min: number, max: number) {
  return (min + max) / 2;
}

function getMultiplier() {
  const measurment = calculatorStore.measurment.value;

  if (measurment === "Grams") return 1;

  if (measurment === "oz. (28.3 g)") return 28.3;

  if (measurment === "Slice (28.0 g)") return 28;

  return 0;
}

function updateProductValues(
  product: GuestCalculatorProduct,
  portion_size: number
) {
  product.portion_size = portion_size;

  let multiplier = getMultiplier();

  let carb_min = multiplier * product.carbs_per_100g_min;
  let carb_max = multiplier * product.carbs_per_100g_max;

  let load_min = multiplier * product.glycemic_load_min;
  let load_max = multiplier * product.glycemic_load_max;

  if (product.portion_size) {
    carb_min =
      multiplier * product.carbs_per_100g_min * (product.portion_size / 100);
    carb_max =
      multiplier * product.carbs_per_100g_max * (product.portion_size / 100);

    load_min = (multiplier * carb_min * product.glycemic_index_min) / 100;
    load_max = (multiplier * carb_max * product.glycemic_index_max) / 100;
  }

  product.cal_carbs_per_100g_min = carb_min;
  product.cal_carbs_per_100g_max = carb_max;

  product.cal_glycemic_load_min = load_min;
  product.cal_glycemic_load_max = load_max;
}

watch(
  () => calculatorStore.measurment.value,
  () => {
    updateProductValues(props.product, props.product.portion_size);
  }
);
</script>
<style
  scoped
  lang="scss"
  src="@/pages_guest/glycemic_calculator/scss/glycemic-calculator-desktop.scss"
/>
