<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import i18n from "@/plugins/i18n";

const route = useRoute();
if (route.query.lang) {
  i18n.global.locale.value = route.query.lang;
}
</script>

<style lang="scss"></style>
