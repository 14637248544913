import {
  createI18n,
  LocaleMessages,
  LocaleMessageValue,
  VueMessageType,
} from "vue-i18n";

const loadLocaleMessages = (): LocaleMessages<
  Record<string, LocaleMessageValue<VueMessageType>>
> => {
  const locales = require.context(
    ".././locales",
    true,
    /[A-Z0-9-_,\s]+\.json$/i
  );
  const messages: LocaleMessages<
    Record<string, LocaleMessageValue<VueMessageType>>
  > = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
};

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "uk",
  warnHtmlMessage: false,
  messages: loadLocaleMessages(),
});

export const locale = i18n.global.locale.value;

export default i18n;
