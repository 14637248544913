<template>
  <div class="calculator">
    <div class="calculator--banner">
      <div class="calculator--banner-column">
        <img
          class="calculator__orange"
          src="@/assets/image/orange.svg"
          alt="Orange logo"
        />
        <div class="calculator--banner-details">
          <h2 class="display-xl">
            {{ $t(`productDetails.title`) }}
          </h2>
          <p class="text-lg-regular">
            <i class="icon icon-info"></i>
            {{ $t(`productDetails.details`) }}
          </p>
        </div>
      </div>
      <div class="calculator--table-content">
        <div class="calculator--table-products">
          <div class="calculator--table-products-header">
            <p class="col-1 text-sm">
              {{ $t(`form.name`) }}
            </p>
            <div class="col-2 calculator--table-header-item">
              <p class="text-sm">{{ $t(`productDetails.carbsPerGram`) }}</p>
              <CustomTooltip :i18Key="'carb'" />
            </div>
            <div class="col-3 calculator--table-header-item">
              <p class="text-sm">{{ $t(`productDetails.glycemicIndex`) }}</p>
              <CustomTooltip :i18Key="'glIndex'" />
            </div>
            <div class="col-4 calculator--table-header-item">
              <p class="text-sm">{{ $t(`productDetails.glycemicLoad`) }}</p>
              <CustomTooltip :i18Key="'glLoad'" />
            </div>
            <p class="col-5 text-sm">
              {{ $t(`form.portion`) }}
              [{{ calculatorStore.measurment.value }}]
            </p>
            <div class="col-6"></div>
          </div>
          <CalculatorRow
            v-for="(product, index) in calculatorStore.products.value"
            :product="product"
            :key="index"
            @delete_clicked="removeProduct(index)"
          />
          <div class="calculator--table-actions">
            <button
              class="btn btn-default btn-icon btn-medium"
              @click="goToSearch"
            >
              <i class="icon icon-plus"></i>
              {{ $t(`productDetails.btn.addPosition`) }}
            </button>
            <router-link
              style="text-decoration: none"
              :to="{
                name: 'GenerateMealsGuest',
              }"
            >
              <button class="btn btn-default btn-icon btn-medium">
                <i class="icon icon-repeat"></i>
                {{ $t(`productDetails.btn.generateMeals`) }}
              </button>
            </router-link>
          </div>
        </div>
        <CalculatorSum />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import router from "@/router";
import CustomTooltip from "@/components/CustomTooltip.vue";
import CalculatorSum from "@/pages_guest/glycemic_calculator/CalculatorSum.vue";
import CalculatorRow from "@/pages_guest/glycemic_calculator/CalculatorRow.vue";

import { useCalculatorStore } from "@/store/calculatorStore";

const calculatorStore = useCalculatorStore();

function goToSearch() {
  router.push({
    name: "LandingGuest",
  });
}

function removeProduct(product_index: number) {
  calculatorStore.products.value.splice(product_index, 1);
}
</script>

<style
  scoped
  lang="scss"
  src="@/pages_guest/glycemic_calculator/scss/glycemic-calculator-desktop.scss"
/>
