<template>
  <div class="custom-tooltip">
    <button
      class="custom-tooltip__button"
      @mouseover="showTooltip"
      @mouseleave="hideTooltip"
    >
      <i class="icon icon-info"></i>
    </button>
    <div class="custom-tooltip--content text-md-regular" v-show="show.value">
      <div class="scope" v-html="$t(`tooltip.${i18Key}`)"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, reactive } from "vue";

const props = defineProps({
  i18Key: { type: String, default: "" },
});

const show = reactive({
  value: false,
});

function showTooltip() {
  show.value = true;
}

function hideTooltip() {
  show.value = false;
}
</script>

<style scoped lang="scss" src="@/components/scss/custom-tooltip.scss" />
