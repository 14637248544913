<template>
  <div class="contact-us">
    <div class="contact-us-title-container">
      <h1 class="text-2xl">
        {{ $t(`contactUs.title`) }}
      </h1>
      <p class="text-lg-regular">
        {{ $t(`contactUs.subTitle`) }}
      </p>
      <div class="contact-us-links">
        <a
          href="mailto:support@glycemicloadcalculator.com"
          class="text-lg-regular"
          >support@glycemicloadcalculator.com</a
        >
      </div>
    </div>
    <div class="contact-us-form">
      <div class="form-group" :class="getValidationClass(vuelidateEmail)">
        <label for="">{{ $t(`form.email`) }}</label>
        <input
          type="text"
          placeholder="mail@gmail.com"
          v-model="email.value"
          @blur="vuelidateEmail.$touch()"
        />
        <span
          class="form-group--error"
          v-if="vuelidateEmail.value.required.$invalid"
        >
          {{ $t(`validation.required`) }}
        </span>
      </div>
      <div class="form-group" :class="getValidationClass(vuelidateName)">
        <label for="">{{ $t(`form.name`) }}</label>
        <input
          type="text"
          placeholder="John"
          v-model="name.value"
          @blur="vuelidateName.$touch()"
        />
        <span
          class="form-group--error"
          v-if="vuelidateName.value.required.$invalid"
        >
          {{ $t(`validation.required`) }}
        </span>
      </div>
      <div class="form-group" :class="getValidationClass(vuelidateMessageText)">
        <label for="">
          {{ $t(`contactUs.yourRequest`) }}
        </label>
        <textarea
          placeholder="Message"
          v-model="message_text.value"
          @blur="vuelidateMessageText.$touch()"
        />
        <span
          class="form-group--error"
          v-if="vuelidateMessageText.value.required.$invalid"
        >
          {{ $t(`validation.required`) }}
        </span>
      </div>
      <div class="contact-us__recaptcha">
        <p class="text-lg-regular">recaptcha</p>
      </div>
      <button class="btn btn-default btn-medium" @click="sendCallback">
        {{ $t(`contactUs.send`) }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

const vuelidateRules = computed(() => {
  return {
    value: { required },
  };
});

const recaptcha_response = reactive({
  value: "" as string | undefined,
});

const email = reactive({
  value: "",
});

const name = reactive({
  value: "",
});

const message_text = reactive({
  value: "",
});

const vuelidateEmail = useVuelidate(vuelidateRules, email);
const vuelidateName = useVuelidate(vuelidateRules, name);
const vuelidateMessageText = useVuelidate(vuelidateRules, message_text);

function sendCallback() {
  vuelidateEmail.value.$touch();
  vuelidateName.value.$touch();
  vuelidateMessageText.value.$touch();
  if (
    vuelidateEmail.value.$invalid ||
    vuelidateName.value.$invalid ||
    vuelidateMessageText.value.$invalid
  ) {
    return;
  }

  if (!recaptcha_response.value) {
    return;
  }
}

function getValidationClass(form: any, showError = false) {
  if (!form["value"]) {
    return { error: false };
  }
  const { $invalid, $pending, $dirty } = form["value"];
  return {
    error: ($invalid && !$pending && $dirty) || showError,
  };
}
</script>

<style scoped lang="scss" src="@/pages_guest/scss/contact-us.scss" />
