<template>
  <div class="insulin-calculator">
    <div class="insulin-calculator--banner">
      <div class="insulin-calculator--banner-column">
        <img
          class="insulin-calculator__lemon"
          src="@/assets/image/lemon.svg"
          alt="Pear logo"
        />
        <div class="insulin-calculator--banner-details">
          <h2 class="display-xl">
            {{ $t(`insulinCalculator.title`) }}
          </h2>
          <p class="text-lg-regular">
            {{ $t(`insulinCalculator.subTitle`) }}
          </p>
        </div>
      </div>
      <div class="insulin-calculator--banner-search">
        <div class="insulin-calculator--search">
          <div class="form-group">
            <div class="form-group__row">
              <input
                v-model="email.value"
                type="text"
                placeholder="example@gmail.com"
                @keyup.enter="submit"
              />
            </div>
          </div>
        </div>
        <button class="btn btn-default btn-large" @click="submit">
          {{ $t(`insulinCalculator.btn.submit`) }}
        </button>
      </div>
    </div>
    <img
      class="insulin-calculator__img"
      src="../assets/image/calculator.png"
      alt="Insulin Dose Calculator"
    />
  </div>
</template>

<script lang="ts" setup>
import { reactive } from "vue";

const email = reactive({
  value: "",
});

function submit() {
  //
}
</script>

<style scoped lang="scss" src="@/pages_guest/scss/insulin-calculator.scss" />
