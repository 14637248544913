<template>
  <div class="privacy-policy">
    <h1 class="text-2xl">Privacy policy</h1>
    <div class="privacy-policy-container">
      <div class="privacy-policy-text-block">
        <h2 class="text-lg-semibold">AGREEMENT TO TERMS</h2>
        <div class="privacy-policy-text-container">
          <p class="text-md-regular">
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”)
            and CryptHub ("Company ," “we," “us," or “our”), concerning your
            access to and use of thehttps://crypthub.iowebsite as well as any
            other media form, media channel, mobile website or mobile
            application related, linked, or otherwise connected thereto
            (collectively, the “Site”). You agree that by accessing the Site,
            you have read, understood, and agreed to be bound by all of these
            Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
            THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST
            DISCONTINUE USE IMMEDIATELY.
          </p>
          <p class="text-md-regular">
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use from time to time. We
            will alert you about any changes by updating the “Last updated” date
            of these Terms of Use, and you waive any right to receive specific
            notice of each such change. Please ensure that you check the
            applicable Terms every time you use our Site so that you understand
            which Terms apply. You will be subject to, and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.
          </p>
          <p class="text-md-regular">
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </p>
        </div>
      </div>
    </div>
    <div class="privacy-policy-container">
      <div class="privacy-policy-text-block">
        <h2 class="text-lg-semibold">INTELLECTUAL PROPERTY RIGHTS</h2>
        <div class="container-text">
          <p class="text-md-regular">
            Unless otherwise indicated, the Site is our proprietary property and
            all source code, databases, functionality, software, website
            designs, audio, video, text, photographs, and graphics on the Site
            (collectively, the “Content”) and the trademarks, service marks, and
            logos contained therein (the “Marks”) are owned or controlled by us
            or licensed to us, and are protected by copyright and trademark laws
            and various other intellectual property rights and unfair
            competition laws of the United States, international copyright laws,
            and international conventions. The Content and the Marks are
            provided on the Site “AS IS” for your information and personal use
            only. Except as expressly provided in these Terms of Use, no part of
            the Site and no Content or Marks may be copied, reproduced,
            aggregated, republished, uploaded, posted, publicly displayed,
            encoded, translated, transmitted, distributed, sold, licensed, or
            otherwise exploited for any commercial purpose whatsoever, without
            our express prior written permission.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss" src="@/pages_guest/scss/privacy-policy.scss" />
