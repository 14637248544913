<template>
  <div class="calculator--table-result">
    <div class="calculator--search-item">
      <div class="calculator--search-block">
        <div class="calculator--search-details">
          <p class="text-md-semibold">
            {{ $t(`productDetails.btn.result`) }}
          </p>
          <div class="calculator--result-body">
            <div class="calculator--result-body-item">
              <p class="text-md-regular">
                {{ $t(`productDetails.carbsPerGram`) }}:
              </p>
              <div class="calculator--result-body-row">
                <p class="text-md-semibold">
                  {{ carbResultMin }} -
                  {{ carbResultMax }}
                  <span
                    :class="
                      getGlycemicCarbsStatus(
                        average(carbResultMin, carbResultMax)
                      )
                    "
                    >({{
                      $t(
                        `status.${getGlycemicCarbsStatus(
                          average(carbResultMin, carbResultMax)
                        )}`
                      )
                    }})</span
                  >
                </p>
                <CustomTooltip :i18Key="'carb'" />
              </div>
            </div>
            <div class="calculator--result-body-item">
              <p class="text-md-regular">
                {{ $t(`productDetails.glycemicIndex`) }}:
              </p>
              <div class="calculator--result-body-row">
                <p class="text-md-semibold">
                  {{ indexResultMin }} -
                  {{ indexResultMax }}
                  <span :class="getGlycemicIndexStatus(indexResultMax)"
                    >({{
                      $t(`status.${getGlycemicIndexStatus(indexResultMax)}`)
                    }})</span
                  >
                </p>
                <CustomTooltip :i18Key="'glIndex'" />
              </div>
            </div>
            <div class="calculator--result-body-item">
              <p class="text-md-regular">
                {{ $t(`productDetails.glycemicLoad`) }}:
              </p>
              <div class="calculator--result-body-row">
                <p class="text-md-semibold">
                  {{ loadResultMin }} -
                  {{ loadResultMax }}
                  <span
                    :class="
                      getGlycemicLoadStatus(
                        average(loadResultMin, loadResultMax)
                      )
                    "
                    >({{
                      $t(
                        `status.${getGlycemicLoadStatus(
                          average(loadResultMin, loadResultMax)
                        )}`
                      )
                    }})</span
                  >
                </p>
                <CustomTooltip :i18Key="'glLoad'" />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="measurment">{{ $t(`form.measurment`) }}</label>
            <div class="form-group__row">
              <div
                class="custom-dropdown"
                @click.stop="dropdownOpen.value = !dropdownOpen.value"
              >
                <input
                  type="text"
                  id="measurment"
                  v-model="calculatorStore.measurment.value"
                  readonly
                />
                <button class="btn btn-icon">
                  <i class="icon icon-arrow-down"></i>
                </button>
                <div class="dropdown-menu" v-show="dropdownOpen.value">
                  <span
                    class="dropdown-item text-lg-regular"
                    @click.stop="calculatorStore.measurment.value = 'Grams'"
                  >
                    Grams
                  </span>
                  <span
                    class="dropdown-item text-lg-regular"
                    @click.stop="
                      calculatorStore.measurment.value = 'oz. (28.3 g)'
                    "
                  >
                    oz. (28.3 g)
                  </span>
                  <span
                    class="dropdown-item text-lg-regular"
                    @click.stop="
                      calculatorStore.measurment.value = 'Slice (28.0 g)'
                    "
                  >
                    Slice (28.0 g)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  getGlycemicCarbsStatus,
  getGlycemicIndexStatus,
  getGlycemicLoadStatus,
} from "@/features/useGlycemicParameters";
import { GuestCalculatorProduct } from "@/services/api/pages/product-detail/entities/guest-product";
import { useCalculatorStore } from "@/store/calculatorStore";
import { computed, reactive } from "vue";

const calculatorStore = useCalculatorStore();

const carbResultMin = computed<number>(() => {
  let carbsMinSum = 0;

  calculatorStore.products.value.forEach((product: GuestCalculatorProduct) => {
    carbsMinSum += product.cal_carbs_per_100g_min;
  });

  return toDecimal(carbsMinSum);
});

const carbResultMax = computed<number>(() => {
  let carbsMaxSum = 0;

  calculatorStore.products.value.forEach((product: GuestCalculatorProduct) => {
    carbsMaxSum += product.cal_carbs_per_100g_max;
  });

  return toDecimal(carbsMaxSum);
});

const indexResultMin = computed<number>(() => {
  let indexMin = (loadResultMin.value / carbResultMin.value) * 100;
  return toDecimal(indexMin);
});

const indexResultMax = computed<number>(() => {
  let indexMax = (loadResultMax.value / carbResultMax.value) * 100;
  return toDecimal(indexMax);
});

const loadResultMin = computed<number>(() => {
  let loadMinSum = 0;

  calculatorStore.products.value.forEach((product: GuestCalculatorProduct) => {
    loadMinSum += product.cal_glycemic_load_min;
  });

  return toDecimal(loadMinSum);
});

const loadResultMax = computed<number>(() => {
  let loadMaxSum = 0;

  calculatorStore.products.value.forEach((product: GuestCalculatorProduct) => {
    loadMaxSum += product.cal_glycemic_load_max;
  });

  return toDecimal(loadMaxSum);
});

const dropdownOpen = reactive({
  value: false,
});

function average(min: number, max: number) {
  return (min + max) / 2;
}

function toDecimal(num: number) {
  if (num % 1 === 0) return num;

  const val = num.toFixed(2);

  return Number(val.replace(/0*$/g, "")); //remove trailing zeros
}
</script>
<style
  scoped
  lang="scss"
  src="@/pages_guest/glycemic_calculator/scss/glycemic-calculator-mobile.scss"
/>
