<template>
  <div class="calculator--search-item">
    <div class="calculator--search-block">
      <div class="calculator--search-details">
        <p class="text-lg-semibold">{{ product.title }}</p>
        <div class="calculator--result-body">
          <div class="calculator--result-body-item">
            <p class="text-md-regular">
              {{ $t(`productDetails.carbsPerGram`) }}:
            </p>
            <div class="calculator--result-body-row">
              <p class="text-md-semibold">
                {{ toDecimal(product.cal_carbs_per_100g_min) }} -
                {{ toDecimal(product.cal_carbs_per_100g_max) }}
                <span
                  :class="
                    getGlycemicCarbsStatus(
                      average(
                        product.cal_carbs_per_100g_min,
                        product.cal_carbs_per_100g_max
                      )
                    )
                  "
                  >({{
                    $t(
                      `status.${getGlycemicCarbsStatus(
                        average(
                          product.cal_carbs_per_100g_min,
                          product.cal_carbs_per_100g_max
                        )
                      )}`
                    )
                  }})</span
                >
              </p>
              <CustomTooltip :i18Key="'carb'" />
            </div>
          </div>
          <div class="calculator--result-body-item">
            <p class="text-md-regular">
              {{ $t(`productDetails.glycemicIndex`) }}:
            </p>
            <div class="calculator--result-body-row">
              <p class="text-md-semibold">
                {{
                  `${product.glycemic_index_min}-${product.glycemic_index_max}`
                }}
                <span
                  :class="getGlycemicIndexStatus(product.glycemic_index_max)"
                  >({{
                    $t(
                      `status.${getGlycemicIndexStatus(
                        product.glycemic_index_max
                      )}`
                    )
                  }})</span
                >
              </p>
              <CustomTooltip :i18Key="'glIndex'" />
            </div>
          </div>
          <div class="calculator--result-body-item">
            <p class="text-md-regular">
              {{ $t(`productDetails.glycemicLoad`) }}:
            </p>
            <div class="calculator--result-body-row">
              <p class="text-md-semibold">
                {{ toDecimal(product.cal_glycemic_load_min) }} -
                {{ toDecimal(product.cal_glycemic_load_max) }}
                <span
                  :class="
                    getGlycemicLoadStatus(
                      average(
                        product.cal_glycemic_load_min,
                        product.cal_glycemic_load_max
                      )
                    )
                  "
                  >({{
                    $t(
                      `status.${getGlycemicLoadStatus(
                        average(
                          product.cal_glycemic_load_min,
                          product.cal_glycemic_load_max
                        )
                      )}`
                    )
                  }})</span
                >
              </p>
              <CustomTooltip :i18Key="'glLoad'" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for=""
            >{{ $t(`form.portion`) }} [{{
              calculatorStore.measurment.value
            }}]</label
          >
          <div class="form-group__row">
            <input
              type="text"
              placeholder="100"
              @input="updateProductValues(product, $event.target?.value)"
            />
            <button class="btn btn-icon">
              <i class="icon icon-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <i class="icon icon-arrow-right"></i>
    </div>
    <div class="calculator__hr"></div>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineProps, defineEmits, watch } from "vue";

import {
  getGlycemicCarbsStatus,
  getGlycemicIndexStatus,
  getGlycemicLoadStatus,
} from "@/features/useGlycemicParameters";
import { useCalculatorStore } from "@/store/calculatorStore";
import { GuestCalculatorProduct } from "@/services/api/pages/product-detail/entities/guest-product";

const calculatorStore = useCalculatorStore();

const emit = defineEmits(["delete_clicked"]);

const props = defineProps({
  product: {
    type: Object as () => GuestCalculatorProduct,
    required: true,
  },
});

function toDecimal(num: number) {
  if (num % 1 === 0) return num.toString();

  const val = num.toFixed(2);

  return val.replace(/0*$/g, ""); //remove trailing zeros
}

function average(min: number, max: number) {
  return (min + max) / 2;
}

function getMultiplier() {
  const measurment = calculatorStore.measurment.value;

  if (measurment === "Grams") return 1;

  if (measurment === "oz. (28.3 g)") return 28.3;

  if (measurment === "Slice (28.0 g)") return 28;

  return 0;
}

function updateProductValues(
  product: GuestCalculatorProduct,
  portion_size: number
) {
  product.portion_size = portion_size;

  let multiplier = getMultiplier();

  let carb_min = multiplier * product.carbs_per_100g_min;
  let carb_max = multiplier * product.carbs_per_100g_max;

  let load_min = multiplier * product.glycemic_load_min;
  let load_max = multiplier * product.glycemic_load_max;

  if (product.portion_size) {
    carb_min =
      multiplier * product.carbs_per_100g_min * (product.portion_size / 100);
    carb_max =
      multiplier * product.carbs_per_100g_max * (product.portion_size / 100);

    load_min = (multiplier * carb_min * product.glycemic_index_min) / 100;
    load_max = (multiplier * carb_max * product.glycemic_index_max) / 100;
  }

  product.cal_carbs_per_100g_min = carb_min;
  product.cal_carbs_per_100g_max = carb_max;

  product.cal_glycemic_load_min = load_min;
  product.cal_glycemic_load_max = load_max;
}

watch(
  () => calculatorStore.measurment.value,
  () => {
    updateProductValues(props.product, props.product.portion_size);
  }
);
</script>
<style
  scoped
  lang="scss"
  src="@/pages_guest/glycemic_calculator/scss/glycemic-calculator-mobile.scss"
/>
